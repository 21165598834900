// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketplace-tsx": () => import("./../../../src/pages/marketplace.tsx" /* webpackChunkName: "component---src-pages-marketplace-tsx" */),
  "component---src-pages-services-e-vo-ep-tsx": () => import("./../../../src/pages/services/EVoEP.tsx" /* webpackChunkName: "component---src-pages-services-e-vo-ep-tsx" */),
  "component---src-pages-services-opto-ep-tsx": () => import("./../../../src/pages/services/OptoEP.tsx" /* webpackChunkName: "component---src-pages-services-opto-ep-tsx" */),
  "component---src-pages-services-solar-ep-tsx": () => import("./../../../src/pages/services/SolarEP.tsx" /* webpackChunkName: "component---src-pages-services-solar-ep-tsx" */)
}

